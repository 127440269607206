import Vue from 'vue'
import Vuex from 'vuex'
import i18n from './modules/i18n'
import Auth from './modules/auth'
import Toplists from "./modules/toplists"
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'

Vue.use(Vuex);

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: true
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  }
}


export default new Vuex.Store({
  state,
  mutations,
  plugins: [createPersistedState({
    key: 'pro',
    storage: {
      getItem: key => Cookies.get(key),
      setItem: (key, value) => {
        Cookies.set(key, value, {expires: 4})
      },
      removeItem: key => Cookies.remove(key)
    }
  })],
  modules: {
    i18n,
    Toplists,
    Auth
  }
})
